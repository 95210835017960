
.environment {
    width: 200px;
    background-color: pink;
    color: deeppink;
    font-weight: bold;
    position: absolute;
    bottom: 20px;
    right: 0px;
    padding: 20px;
}