.builder {
    margin: 0 auto;
    margin-top: 100px;
    width: 960px;
    margin-bottom: 100px;
}

.picker {
    /*background: #282c34;*/
}

.picker__label {
    margin: 0;
    /*padding: 0;*/
    font-size: 16px;
    /*font-weight: bold;*/
    padding: 20px;
    text-align: left;
}

.picker__scroller {
    margin: 0;
    padding: 0;
    width: 960px;
    height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.picker__scroller img {
    width: 200px;
    cursor: pointer;
    /*margin: 0 auto;*/
    padding: 20px;
    margin: 0;
    /*padding: 0;*/
}

.picker__scroller img:hover {
    background-color: #dbdbdc;
}